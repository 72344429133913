import { UserType } from '@innedit/innedit-type';
// import { diff } from 'deep-object-diff';
import { PageProps } from 'gatsby';
import { UserData } from 'packages/innedit';
// import isNil from 'lodash/isNil';
// import omitBy from 'lodash/omitBy';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Admin/Form';
import params from '~/params/user/index.json';
import TemplateAdmin from '~/templates/admin';
import requireUser from '~/utils/requireUser';

const PageAdminUsersUpdate: FC<PageProps> = props => {
  const {
    params: { id },
  } = props;

  if (!id) {
    return <div>l utilisateur n existe pas</div>;
  }

  const handleOnSubmit = (
    values: Partial<UserType>,
    oldValues?: Partial<UserType>,
  ) => {
    // const { email } = values;
    // if (oldValues) {
    //   const diffValues = omitBy(diff(oldValues, values), isNil);
    // }
  };

  return (
    <TemplateAdmin>
      <CMSView>
        <Form
          docId={id}
          itemPathnamePrefix="/admin/users/"
          model={new UserData({ params })}
          name="user"
          onSubmit={handleOnSubmit}
          type="update"
        />
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminUsersUpdate, 'admin');
